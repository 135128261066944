import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import { compose, mapProps } from '@shakacode/recompose';
import { Box, Button } from '@popmenu/common-ui';
import { connect } from 'react-redux';

import { findElement, offset } from '../../utils/dom';
import { isNotTabKey } from '../../utils/events';
import { readableFontColor } from '../../utils/colors';
import { classNames } from '../../utils/withStyles';
import { withWindowContext } from '../../shared/WindowProvider';
import { themeShape, withTheme } from '../../utils/withTheme';
import { setSelectedSectionIndex, setSelectedSectionSlug, setShouldPreloadSections } from '../../shared/MenuItemCartActions';

import NextMenuGroupMobileSelect from './NextMenuGroupMobileSelect';
import { withVirtualizedMenuContext } from './Menu/VirtuosoMenu/VirtualizedMenuContext';
import { addUserScrollListener, removeUserScrollListener } from './Menu/VirtuosoMenu/virtuosoScrollHelpers';
import { executeWithProgressBar } from '../../utils/postponed';

class NextMenuGroupTabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuSectionId: null,
      tabsStuck: false,
    };
    this.onScroll = this.onScroll.bind(this);
    this.onUserScroll = this.onUserScroll.bind(this);
    this.tabsRef = React.createRef();
  }

  componentDidMount() {
    addUserScrollListener(this.onUserScroll);
    this.props.addScrollListener(this.onScroll);
  }

  componentWillUnmount() {
    removeUserScrollListener(this.onUserScroll);
    this.props.removeScrollListener(this.onScroll);
  }

  onUserScroll() {
    // Set menu section based on scroll position
    const selectedMenu = this.props.menus.filter(menu => menu.id === this.props.selectedMenuId)[0];
    let menuSectionId = null;
    if (selectedMenu && selectedMenu.enabledSections && selectedMenu.enabledSections.length) {
      const elements = selectedMenu.enabledSections.map((section) => {
        const el = findElement(`#section-${section.slug}`);
        if (el) {
          return [section.id, el];
        }
        return null;
      }).filter(Boolean);

      for (let i = elements.length - 1; i >= 0; i -= 1) {
        const [sectionId, sectionEl] = elements[i];
        if (sectionEl && (i === 0 || offset(sectionEl).top <= this.props.navbarHeightWithOffset + 16)) {
          menuSectionId = sectionId;
          break;
        }
      }
    }

    this.setState({ menuSectionId });
  }

  onScroll(windowState) {
    if (!windowState.scrolled && !this.state.tabsStuck) {
      return;
    }

    // Update sidebar sticky state
    let tabsStuck = false;
    if (this.tabsRef.current) {
      if (offset(this.tabsRef.current).top <= windowState.navbarHeightWithOffset + 16) {
        tabsStuck = true;
      }
    }

    this.setState({ tabsStuck });
  }

  onMenuClick(e, menuId) {
    e.preventDefault();

    executeWithProgressBar(() => {
      // Update selected menu
      this.props.setSelectedMenu({ id: menuId });

      // Focus menu panel for keyboard/SR users
      if (isNotTabKey(e)) {
        const tabPanel = findElement(`#menu-tabpanel-${menuId}`);
        if (tabPanel) {
          tabPanel.focus();
        }
      }
    });

    return false;
  }

  render() {
    const { classes, includeItemLink, isOrderingAvailable, menus, menuTabsLayout, navbarHeightWithOffset, selectedMenu, selectedMenuId, setSelectedMenu, showMobileTabs, theme, mobile, scrollToVirtuosoIndex } = this.props;
    const { menuSectionId, tabsStuck } = this.state;
    const { isProfileV2Enabled, isMobileOrderDetailsV2Enabled } = mobile?.restaurant?.featureSetting || {};
    const profileCardHeight = isProfileV2Enabled && !isMobileOrderDetailsV2Enabled && mobile.isLoggedIn ? 72 : 0;
    const offerCardHeight = isProfileV2Enabled && !isMobileOrderDetailsV2Enabled && !mobile.isLoggedIn ? 53 : 0;
    const orderInfoCardHeight = isMobileOrderDetailsV2Enabled ? 52 : 0;
    const menuPaddingTop = `${navbarHeightWithOffset + profileCardHeight + offerCardHeight + orderInfoCardHeight + 16}px`;

    // Override selected tab theme with dropdown selection for mobile
    if (showMobileTabs) {
      return (
        <Box ref={this.tabsRef} className={tabsStuck ? classes.fixedMobileTabsMainContainer : null}>
          <Box
            className={classNames(
              classes.staticMobileTabsContainer,
              tabsStuck ? classes.staticMobileTabsContainerStuck : null,
            )}
          >
            <NextMenuGroupMobileSelect
              classes={classes}
              isOrderingAvailable={isOrderingAvailable}
              menus={menus}
              selectedMenuId={selectedMenuId}
              setSelectedMenu={setSelectedMenu}
            />
          </Box>
          <Box
            className={classNames(
              classes.fixedMobileTabsContainer,
              tabsStuck ? classes.fixedMobileTabsContainerStuck : null,
            )}
            // If profileV2 is disabled, it defaults to original padding. Depending if the user is logged in or not, the padding will differ due to the difference in rendered components. If any profile tags get altered, this will need to be altered too. 72 = ProfileOffersTag height. 53 = UserEmailCheck height.
            style={{ paddingTop: menuPaddingTop }}
          >
            <NextMenuGroupMobileSelect
              classes={classes}
              isOrderingAvailable={isOrderingAvailable}
              menus={menus}
              selectedMenuId={selectedMenuId}
              setSelectedMenu={setSelectedMenu}
              additionalScrollOffset={mobile?.restaurant?.featureSetting?.isProfileV2Enabled && (mobile?.isLoggedIn ? 72 : 53)}
            />
          </Box>
        </Box>
      );
    }

    // Display tabs based on selected theme.menuTabsLayout option
    switch (menuTabsLayout) {
      case 'material_menu_tabs_layout':
        return (
          <div
            className={classNames(
              classes.materialTabsContainer,
              tabsStuck ? classes.materialTabsContainerStuck : null,
            )}
            ref={this.tabsRef}
            style={{
              backgroundColor: tabsStuck ? ((selectedMenu && selectedMenu.backgroundColor) || theme.defaultBackgroundColor || '#FFF') :
                (isOrderingAvailable ? theme.primaryColor : null),
              color: tabsStuck ? theme.menuItemDescriptionFont.color :
                (isOrderingAvailable && theme.primaryColor ? readableFontColor(theme.primaryColor) : theme.menuItemDescriptionFont.color),
              paddingTop: tabsStuck ? navbarHeightWithOffset : 0,
            }}
          >
            <Tabs
              aria-label="Menus"
              className="pm-material-menu-tabs"
              onChange={(e, menuId) => this.onMenuClick(e, menuId)}
              scrollButtons="auto"
              value={selectedMenuId}
              variant="scrollable"
            >
              {menus.map(menu => (
                <Tab
                  key={menu.id}
                  aria-controls={`menu-tabpanel-${menu.id}`}
                  aria-selected={selectedMenuId === menu.id}
                  className={classNames(
                    'pm-material-menu-tab',
                    classes.menuTab,
                  )}
                  component={includeItemLink ? 'a' : 'button'}
                  href={includeItemLink ? menu.url : null}
                  id={`menu-tab-${menu.id}`}
                  label={menu.name}
                  value={menu.id}
                />
              ))}
            </Tabs>
          </div>
        );
      case 'sidebar_menu_tabs_layout':
        return (
          <div
            aria-label="Menus"
            className={classNames(
              'pm-menu-sidebar',
              classes.sidebarTabsContainer,
            )}
            ref={this.tabsRef}
            role="tablist"
            tabIndex={0}
            style={{ paddingTop: tabsStuck ? navbarHeightWithOffset : 0 }}
          >
            {menus.map((menu) => {
              const selected = selectedMenuId === menu.id;
              return (
                <React.Fragment key={menu.id}>
                  <Button
                    aria-controls={`menu-tabpanel-${menu.id}`}
                    aria-selected={selected}
                    className={classNames(
                      'pm-menu-sidebar-tab',
                      selected ? 'pm-menu-sidebar-tab-selected' : null,
                      classes.sidebarTabButton,
                      selected ? classes.sidebarTabButtonSelected : null,
                    )}
                    component={includeItemLink ? 'a' : 'button'}
                    href={includeItemLink ? menu.url : null}
                    id={`menu-tab-${menu.id}`}
                    onClick={e => this.onMenuClick(e, menu.id)}
                    onKeyUp={e => this.onMenuClick(e, menu.id)}
                    role="tab"
                    tabIndex="0"
                    variant="text"
                  >
                    {menu.name}
                  </Button>
                  {selected && menu.enabledSections && menu.enabledSections.map((section, index) => (
                    section.isHeaderEnabled ? (
                      <Button
                        key={section.id}
                        className={classNames(
                          'pm-menu-sidebar-tab-section',
                          menuSectionId === section.id ? 'pm-menu-sidebar-tab-section-selected' : null,
                          classes.sidebarTabButtonSection,
                          menuSectionId === section.id ? classes.sidebarTabButtonSectionSelected : null,
                        )}
                        onClick={() => {
                          executeWithProgressBar(() => {
                            this.props.setShouldPreloadSections(true);
                            this.props.setSelectedSectionSlug(section.slug);
                            this.props.setSelectedSectionIndex(index);

                            // Directly activating the tab
                            this.setState({ menuSectionId: section.id });
                            const featuredSectionOffset = menu.enabledFeaturedSection ? 1 : 0;
                            scrollToVirtuosoIndex({ index: index + featuredSectionOffset, offset: offerCardHeight + navbarHeightWithOffset });
                          });
                        }}
                        tabIndex="0"
                        variant="text"
                        role="tab"
                      >
                        {section.name}
                      </Button>
                    ) : null
                  ))}
                </React.Fragment>
              );
            })}
          </div>
        );
      case 'legacy_menu_tabs_layout':
      default:
        return (
          <div
            aria-label="Menus"
            className={classNames(
              'pm-toggles-wrap',
              classes.legacyTabList,
            )}
            role="tablist"
          >
            {menus.map((menu) => {
              const selected = selectedMenuId === menu.id;
              return (
                <a
                  key={menu.id}
                  aria-controls={`menu-tabpanel-${menu.id}`}
                  aria-selected={selected}
                  className={classNames(
                    'pm-menu-toggle',
                    `pm-menu-toggle-${menu.slug}`,
                    selected ? 'active' : null,
                    classes.legacyTabButton,
                    selected ? classes.legacyTabButtonSelected : null,
                  )}
                  href={includeItemLink ? menu.url : null}
                  id={`menu-tab-${menu.id}`}
                  onClick={e => this.onMenuClick(e, menu.id)}
                  onKeyUp={e => this.onMenuClick(e, menu.id)}
                  role="tab"
                  tabIndex="0"
                >
                  {menu.name}
                </a>
              );
            })}
          </div>
        );
    }
  }
}

NextMenuGroupTabs.defaultProps = {
  selectedMenuId: null,
};

NextMenuGroupTabs.propTypes = {
  addScrollListener: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  menus: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  menuTabsLayout: PropTypes.string.isRequired,
  navbarHeightWithOffset: PropTypes.number.isRequired,
  removeScrollListener: PropTypes.func.isRequired,
  scrollToVirtuosoIndex: PropTypes.func.isRequired,
  selectedMenuId: PropTypes.number,
  setSelectedMenu: PropTypes.func.isRequired,
  showMobileTabs: PropTypes.bool.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withTheme,
  withWindowContext,
  withVirtualizedMenuContext,
  mapProps(({ window, activeVirtuosoSection, virtuosoOffset, virtuosoRef, ...props }) => ({
    ...props,
    addScrollListener: window.addScrollListener,
    navbarHeightWithOffset: window.navbarHeightWithOffset,
    removeScrollListener: window.removeScrollListener,
  })),
  connect(({ menuItemCart: {
    selectedSectionSlug,
  } }) => ({
    selectedSectionSlug,
  }), {
    setSelectedSectionIndex,
    setSelectedSectionSlug,
    setShouldPreloadSections,
  }),
)(NextMenuGroupTabs);
