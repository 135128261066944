import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { compose, mapProps } from '@shakacode/recompose';
import { toInt } from '../../utils/numbers';
import { classNames } from '../../utils/withStyles';
import { setSelectedSectionIndex, setSelectedSectionOffset, setSelectedSectionSlug, setShouldPreloadSections, setFocusFirstSectionItemEffect } from '../../shared/MenuItemCartActions';
import { withWindowContext } from '../../shared/WindowProvider';
import { VirtualizedMenuContext } from './Menu/VirtuosoMenu/VirtualizedMenuContext';
import { executeWithProgressBar } from '../../utils/postponed';

const NextMenuGroupMobileSelect = ({ additionalScrollOffset, classes, isOrderingAvailable, navbarHeight, menus, navbarHeightWithOffset, selectedMenuId, setSelectedMenu }) => {
  const dispatch = useDispatch();
  const shouldPreloadSections = useSelector(state => state.menuItemCart.shouldPreloadSections);
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  const mergedSections = useCallback((menu) => {
    let currentSections;
    if (isOrderingAvailable) {
      currentSections = menu.enabledSections.filter(s => s.isOrderingSection);
    } else {
      currentSections = menu.enabledSections;
    }
    if (menu.enabledFeaturedSection) {
      currentSections = [
        {
          ...menu.enabledFeaturedSection,
          slug: 'featuredslug',
        },
        ...currentSections,
      ];
    }
    return currentSections;
  }, [isOrderingAvailable]);

  const { scrollToVirtuosoIndex } = useContext(VirtualizedMenuContext);

  return (
    <select
      aria-label="Select a menu"
      className={classNames(classes.mobileTabsDropdown, 'pm-mobile-tabs-dropdown')}
      onChange={(e) => {
        if (!e.target.value) {
          return;
        }
        const { target } = e;
        executeWithProgressBar(() => {
          if (!shouldPreloadSections) {
            dispatch(setShouldPreloadSections(true));
          }
          const idParts = target.value.split('-');
          const menuId = toInt(idParts[0]);
          const sectionIndex = toInt(idParts[1]);
          const sectionSlug = idParts.splice(2).join('-');
          dispatch(setSelectedSectionSlug(sectionSlug));
          dispatch(setSelectedSectionIndex(sectionIndex));
          dispatch(setSelectedSectionOffset(50 + (additionalScrollOffset || 0)));
          dispatch(setFocusFirstSectionItemEffect('active'));
          setSelectedMenu({ id: menuId, scroll: true });
          target.blur();
          scrollToVirtuosoIndex({
            index: sectionIndex,
            offset: navbarHeightWithOffset + navbarHeight,
          });
        });
      }}
      disabled={!isHydrated}
    >
      {menus.map((menu) => {
        const sectionsToShow = mergedSections(menu);
        return (
          <React.Fragment key={menu.id}>
            <option value={menu.id}>
              {menu.name}
            </option>
            {!!(selectedMenuId === menu.id && sectionsToShow?.length) && (
              <optgroup label={`${menu.name} Sections`}>
                {sectionsToShow.map((section, index) => {
                  const optionName = `- ${section.name}`;
                  return (
                    <option key={section.id} value={`${menu.id}-${index}-${section.slug}`}>
                      {optionName}
                    </option>
                  );
                })}
              </optgroup>
            )}
          </React.Fragment>
        );
      })}
    </select>
  );
};

NextMenuGroupMobileSelect.defaultProps = {
  isOrderingAvailable: false,
  selectedMenuId: null,
};

NextMenuGroupMobileSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  isOrderingAvailable: PropTypes.bool,
  menus: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  selectedMenuId: PropTypes.number,
  setSelectedMenu: PropTypes.func.isRequired,
};

export default compose(
  withWindowContext,
  mapProps(({ window, ...props }) => ({
    ...props,
    navbarHeight: window.navbarHeight,
    navbarHeightWithOffset: window.navbarHeightWithOffset,
  })),
)(NextMenuGroupMobileSelect);
